.action-items_page {
    display: flex;
    flex-flow: row nowrap;
    padding: 5px;
}

.categories-details__main {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.categories-list__main {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-bottom: 4px;
    padding: 4px;
}