.reviews_container {
    display: flex;
    flex-flow: column nowrap;
}

.review_item__card {
    background-color: white;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 7px;
    padding: 7px;
    margin: 5px;
    display: flex;
    flex-flow: column nowrap;
}

header.review_item__card_header {
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    align-items: center;
    width: 100%;
    span {
        margin: 5px;
    }
}

.review_edit__form {
    padding: 7px;
    display: flex;
    flex-flow: column nowrap;
    width: 95%;
}

.review_item__comment {
    font-size: 12px;
    padding: 4px;
}

.comment_img__content {
    display: flex;
    flex-flow: row nowrap;
}

.comment_img {
    width: 90px;
    margin: 5px;
    height: 90px;
}