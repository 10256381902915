.page_layout_content {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
}

.page_layout_children__content {
    margin-bottom: 40px;
}

.page_layout_children {
    flex-grow: 1;
    margin-top: 70px;
    display: flex;
    position: absolute;
    flex-flow: column nowrap;
    left: 240px + 13px;
    width: calc(100% - 290px);
}

.page_layout_children__title {
    margin-bottom: 10px;
    font-size: 1.3em;
    padding: 5px;
}

@media(max-width: 600px) {
    .page_layout_children {
        left: 0;
        width: 100%;
    }
}