.login-layout-main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.login-layout-children {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}