.login-card {
    min-width: 400px;
    position: absolute;
    top: 15%;
    padding: 0;
}

.login-card-header {
    display: flex;
    font-size: 1.2em;
    justify-content: center;
    padding: 10px;
}

.login-card-content {
    display: flex;
    padding: 10px;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.login-logo {
    display: flex;
    width: 100%;
    justify-content: center;
    
}

.login-logo__wrapper {
    padding: 10px;
}
