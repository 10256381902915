.products-create_main {
    display: flex;
    padding: 10px;
}

form {
    width: 100%;
}

.product-type_select {
    max-width: 200px;
}

.product-edit-page_main {
    padding: 10px;
}

.products-page_actions {
    padding: 7px;
}

.product-edit_form {
    display: flex;
    flex-flow: column nowrap;
}

.variation-list {
    display: flex;
    flex-flow: row wrap;
    padding: 5px;
}

.product-table_img {
    max-width: 60px;
}

.product-edit-page_main {
    display: flex;
    flex-flow: column nowrap;
}

.product-edit-page_actions {
    padding: 5px;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: flex-end;
}

.meta-props_main {
    padding: 5px;
}

.product-images_list {
    display: flex;
    overflow: auto;
    position: relative;
    flex-flow: row nowrap;
    img {
        max-width: 120px;
        max-height: 120px;
        margin: 3px;
    }

    h5 {
        margin: 0;
        padding: 0;
    }
    
    span .img-main {
        margin: 3px;
        cursor: pointer;
    }
}

.product-tags-main {
    display: flex;
    padding: 7px;
}

.product-attr-content {
    padding: 5px;
}

.product-attributes_list {
    max-width: 400px;
}

.description_editor__main {
    margin: 10px 0 10px 0;
    min-height: 200px;
}