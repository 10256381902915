
.accordion_content__main {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
}

.order_header__main {
    display: flex;
    flex-flow: row nowrap;
    overflow: auto;
    width: 80%;
    padding: 15px;
    justify-content: space-between;
}

.accordion_content__main {
    display: flex;
    flex-flow: row nowrap;
    overflow: auto;
}

.order_id__cell, .client_info__cell {
    display: flex;
    flex-flow: column nowrap;
}


.order_products_imgs__main {
    display: flex;
    flex-flow: row nowrap;
}

.product_img__cell {
    img {
        width: 50px;
    }
}

.client_exp_info__main {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    h3 {
        padding: 0;
        margin: 0;
        font-size: 14px;
    }
}

.order_accordion_details__main {
    width: 100%;
}

.client_exp_info__content, .client_exp_address__content {
    display: flex;
    flex-flow: column nowrap;
}

@media(min-width: 800px) {
    .order_id__cell, .order_total__cell, .client_info__cell, .client_info__cell  .order_status__cell,
    .order_products_imgs__main {
        width: 20%;
    }
}
