.articles__content {

}

.article_card__main {
    background-color: white;
    border: 1px solid #dddddd;
    padding: 0 15px 0 15px;
    margin: 5px;
}

.article_header__actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}