.login-main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
   
}

.login-form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
}

.social-login {
    display: flex;
    flex-flow: row nowrap;
}

.login-additional-links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    p {
        margin: 4px;
        cursor: pointer;
    }
    p:hover {
        color: #5c6bc0;
    }
}

