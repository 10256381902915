
.promocode_container {
    p {
        flex-grow: 1;
    }
    background-color: white;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 7px;
    padding: 7px;
    min-width: 100%;
    margin: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}